import { Request } from '../utils';
import { ApiSettings } from './ApiSettings';

const BaseUrl = `${ApiSettings.backendUrl}authenticate`;

export class LgpdApi {
	static LgpdAceite(data) {
		const url = `${BaseUrl}/lgpd?loja=${data.loja}`;
		return Request.Post(url, data);
	}

	static LgpdTermo(loja) {
		const url = `${BaseUrl}/lgpd?loja=${loja}`;
		return Request.Get(url);
	}

	static ContinuarSemAceitar(data) {
		const url = `${BaseUrl}/lgpd/continuar`;
		return Request.Post(url, data);
	}
}
