import { Request } from '../utils';
import { ApiSettings } from './ApiSettings';

const BaseUrl = `${ApiSettings.backendUrl}consent`;

export class ConsentApi {
	static Get(returnUrl) {
		const url = `${BaseUrl}${returnUrl}`;
		return Request.Get(url);
	}

	static GrantAccess(data) {
		const url = `${BaseUrl}/grant`;
		return Request.Post(url, data);
	}
}
