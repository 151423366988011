import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export function InputPassword({
	placeholder,
	name,
	value,
	onChange,
	label,
	labelWidth,
	className,
	error,
	onKeyPress
}) {
	const [showPassword, setShowPassword] = useState(false);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<FormControl fullWidth variant="outlined" className={className}>
			<InputLabel htmlFor={name}>{label}</InputLabel>
			<OutlinedInput
				required
				placeholder={placeholder}
				name={name}
				id={name}
				type={showPassword ? 'text' : 'password'}
				value={value}
				onChange={onChange}
				onKeyPress={onKeyPress}
				error={error}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle senha visível"
							onClick={() => setShowPassword(!showPassword)}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <Visibility /> : <VisibilityOff />}
						</IconButton>
					</InputAdornment>
				}
				labelWidth={labelWidth}
			/>
		</FormControl>
	);
}

InputPassword.propTypes = {
	placeholder: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onKeyPress: PropTypes.func,
	label: PropTypes.string.isRequired,
	labelWidth: PropTypes.number,
	error: PropTypes.bool,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

InputPassword.defaultProps = {
	labelWidth: 0,
	error: false,
	className: '',
	onKeyPress: () => {}
};
