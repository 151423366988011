import { Request } from '../utils';
import { ApiSettings } from './ApiSettings';

const BaseUrl = `${ApiSettings.backendUrl}authenticate`;

export class HeimdallApi {
	static Login(data) {
		const url = `${BaseUrl}/login`;
		return Request.Post(url, data);
	}

	static ValidateAccessCode(data) {
		const url = `${BaseUrl}/accesscode`;
		return Request.Post(url, data);
	}

	static TrocarLoja(data) {
		const url = `${BaseUrl}trocar-loja`;
		return Request.Put(url, data);
	}

	static RecuperarSenha(data) {
		const url = `${BaseUrl}/recuperar-senha`;
		return Request.Post(url, data);
	}

	static AlterarSenha(data) {
		const url = `${BaseUrl}/alterar-senha`;
		return Request.Put(url, data);
	}

	static PrimeiroAcesso(data) {
		const url = `${BaseUrl}/primeiro-acesso`;
		return Request.Post(url, data);
	}

	static ReenvioCodigoAcesso(data) {
		const url = `${BaseUrl}/reenvio-codigo-acesso`;
		return Request.Post(url, data);
	}

	static ReenvioRecuperarSenha(data) {
		const url = `${BaseUrl}/reenvio-recuperar-senha`;
		return Request.Post(url, data);
	}

	static ReenvioPrimeiroAcesso(data) {
		const url = `${BaseUrl}/reenvio-primeiro-acesso`;
		return Request.Post(url, data);
	}

	static BuscarErro(data) {
		const url = `${BaseUrl}/error?errorId=${data}`;
		return Request.Get(url);
	}

	static UnlockUser(data) {
		const url = `${BaseUrl}/unlock`;
		return Request.Post(url, data);
	}

	static ProfileComplete(data) {
		const url = `${BaseUrl}/profile-complete`;
		return Request.Post(url, data)
	}

	static LoginComplete(data) {
		const url = `${BaseUrl}/login-complete`;
		return Request.Post(url, data)
	}
}
