import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { FbitsButton } from '../FbitsButton';

import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function FirstAccessSend({ nextStep }) {
	const context = React.useContext(FbitsLoginContext);
	const location = useLocation();

	const [email, setEmail] = React.useState('');
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const enviarCodigo = () => {
		setLoading(true);

		const query = new URLSearchParams(location.search);

		const primeiroAcesso = {
			email,
			returnUrl: query.get('ReturnUrl'),
		};

		HeimdallApi.PrimeiroAcesso(primeiroAcesso)
			.then((response) => {
				if (response.hasError) {
					setError(response.message);
				} else {
					context.setEmailPrimeiroAcesso(response.email);
					nextStep('firstAccessReceive');
				}

				setLoading(false);
			})
			.catch(() => {
				setError('Erro ao enviar código de primeiro acesso');
				setLoading(false);
			});
	};

	return (
		<div className="slide-left">
			<h1>Primeiro Acesso</h1>
			<p className="mb-15">Insira seu e-mail para registrar suas informações de login:</p>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					error={Boolean(error)}
					helperText={error}
					variant="outlined"
					className="mb-15"
					placeholder="seuemail@exemplo.com.br"
					label="E-mail"
					type="email"
					name="firstAccess"
					id="firstAccess"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<FbitsButton
					fullWidth
					color="secondary"
					text={!loading ? 'Enviar' : 'Carregado...'}
					disabled={loading}
					size="large"
					onClick={enviarCodigo}
				/>
			</form>

			<div className="form__link">
				<button type="button" onClick={() => nextStep('login')} className="text-default text-link link__position link__position--bottom-left">
					VOLTAR
				</button>
			</div>
		</div>
	);
}

FirstAccessSend.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
