import { HeimdallSettings } from '../HeimdallSettings';
import { EnumEnvironment } from './EnumEnvironment';

// eslint-disable-next-line import/no-mutable-exports
let ApiSettings = {
	backendUrl: '',
	authUrl: '',
	fbitsApiUrl: ''
};

switch (HeimdallSettings.environment) {
	case EnumEnvironment.HLOG:
		ApiSettings = {
			backendUrl: 'https://accounts-hlog.fbits.net/api/',
			authUrl: 'https://authidm.fbits.net/',
			fbitsApiUrl: 'https://api.fbits.net/'
		};
		break;
	case EnumEnvironment.DEBUG:
		ApiSettings = {
			backendUrl: 'https://localhost:5001/api/',
			authUrl: 'http://localhost:58580/',
			fbitsApiUrl: 'https://api.fbits.net/'
		};
		break;
	default:
		ApiSettings = {
			backendUrl: 'https://accounts.fbits.net/api/',
			authUrl: 'https://authidm.fbits.net/',
			fbitsApiUrl: 'https://api.fbits.net/'
		};
		break;
}

export { ApiSettings };
