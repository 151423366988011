const CryptoJS = require('crypto-js');

export class Crypto {
	/**
	 * @param {object} value
	 */
	static encrypt(value) {
		return CryptoJS.AES.encrypt(
			JSON.stringify(value),
			'63726970746f67726172766f6365646576656d65756a6f76656d7061646177616e'
		).toString();
	}

	/**
	 * @param {object} value
	 */
	static decrypt(value) {
		const bytes = CryptoJS.AES.decrypt(
			value,
			'63726970746f67726172766f6365646576656d65756a6f76656d7061646177616e'
		);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	}
}
