import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FbitsButton, InputPassword, FbitsTooltip, FbitsPopper } from '../index';
import { Util } from '../../utils';

import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function RecoverPasswordConfirm({ nextStep }) {
	const { setEmailRecuperarSenha, chaveRecuperarSenha } = useContext(FbitsLoginContext);
	const location = useLocation();

	const [values, setValues] = useState({
		chave: '',
		senha: '',
		confirmarSenha: '',
	});
	const [error, setError] = useState(false);
	const [strength, setStrength] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [errorAlterarSenha, setErrorAlterarSenha] = useState('');
	const [loadingAlterarSenha, setLoadingAlterarSenha] = useState(false);

	useEffect(() => {
		if (chaveRecuperarSenha && values.chave !== chaveRecuperarSenha) {
			setValues({ ...values, chave: chaveRecuperarSenha });
		}
	}, [chaveRecuperarSenha, values]);

	const handleFormRecoverPassword = (name) => (event) => {
		const password = event.target.value;
		const rules = event.currentTarget;
		const strengthLength = strength.length;

		setStrength(Util.PasswordStrength(password));
		setValues({ ...values, [name]: password });

		if (strengthLength > 0) {
			setAnchorEl(rules);
		} else {
			setAnchorEl(null);
		}
	};

	const handleForm = (name) => (event) => {
		const text = event.target.value;
		const rules = event.currentTarget;
		setValues({ ...values, [name]: text });

		if (values.senha !== text && name === 'confirmarSenha') {
			setError(true);
			setAnchorEl(rules);
		} else {
			setError(false);
			setAnchorEl(null);
		}
	};
	const changePassword = () => {
		setLoadingAlterarSenha(true);

		const query = new URLSearchParams(location.search);
		const redirectUri = query.get('RedirectUri');
		const alterPass = {
			...values,
			returnUrl: query.get('ReturnUrl'),
		};

		HeimdallApi.AlterarSenha(alterPass)
			.then((response) => {
				if (response.hasError) {
					setErrorAlterarSenha(response.message);
				} else {
					setEmailRecuperarSenha(response.email);
					console.log(response);
					if (response && response.returnUrl) {
						window.location.href = response.returnUrl;
					} else if (redirectUri) {
						window.location.href = redirectUri;
					} else {
						window.location.href = '/loja-nao-encontrada';
					}
				}

				setLoadingAlterarSenha(false);
			})
			.catch(() => {
				setErrorAlterarSenha('Erro ao alterar senha do usuário');
				setLoadingAlterarSenha(false);
			});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			changePassword();
		}
	};

	return (
		<div className="slide-left">
			<h1>
				Recuperar senha
				<div className="float-right">
					<FbitsTooltip
						title={
							<ul>
								<li className="mb-5">
									<b>Sua senha deve ter</b>:
								</li>
								<li>- letra minúscula (a-z)</li>
								<li>- letra maiúscula (A-Z)</li>
								<li>- números (0-9)</li>
								<li>- caracteres especiais (!@#$)</li>
								<li>- mínimo de 8 caracteres</li>
							</ul>
						}
						placement="right-start"
						arrow
					>
						<Info style={{ color: '#59667B' }} />
					</FbitsTooltip>
				</div>
			</h1>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					variant="outlined"
					className="mb-15"
					placeholder="XXXXXXXXXXX"
					label="Chave"
					name="chave"
					id="chave"
					value={values.chave}
					onChange={handleForm('chave')}
				/>

				<InputPassword
					placeholder="Digite sua senha"
					name="senha"
					value={values.senha}
					onChange={handleFormRecoverPassword('senha')}
					label="Senha"
					labelWidth={50}
					className="mb-15"
					error={strength.length > 0}
					aria-describedby="senha"
				/>

				<FbitsPopper open={Boolean(anchorEl) && strength.length > 0} anchorEl={anchorEl}>
					<b>Insira pelo menos uma:</b>
					{strength.map((x) => (
						<span key={x} className="d-block">
							- {x}
						</span>
					))}
				</FbitsPopper>

				<InputPassword
					placeholder="Confirme sua senha"
					name="confirmarSenha"
					value={values.confirmarSenha}
					onChange={handleForm('confirmarSenha')}
					label="Confirmar Senha"
					labelWidth={120}
					className="mb-15"
					error={error}
					onKeyPress={handleKeyPress}
				/>

				<FbitsPopper open={error} anchorEl={anchorEl}>
					Senhas são diferentes
				</FbitsPopper>

				<Typography style={{ color: '#D32F2F', paddingBottom: 10, paddingLeft: 5 }}>{errorAlterarSenha}</Typography>

				<FbitsButton
					fullWidth
					color="secondary"
					size="large"
					onClick={changePassword}
					disabled={loadingAlterarSenha}
					text={!loadingAlterarSenha ? 'Alterar' : 'Carregado...'}
				/>
			</form>

			<div className="form__link">
				<button
					onClick={() => nextStep('recoverPasswordSend')}
					type="button"
					className="text-default text-link link__position link__position--bottom-left"
				>
					VOLTAR
				</button>
			</div>
		</div>
	);
}

RecoverPasswordConfirm.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
