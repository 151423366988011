import React from 'react';
import PropTypes from 'prop-types';
import { Popper, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	paper: {
		minWidth: 190,
		position: 'absolute',
		left: '120px',
		padding: theme.spacing(2),
		backgroundColor: '#DC3545',
		fontSize: 12,
		color: '#fff',
		borderRadius: 3
	}
}));

export function FbitsPopper({ anchorEl, open, children }) {
	const classes = useStyles();
	return (
		<Popper
			placement="right-start"
			id={anchorEl}
			open={open}
			anchorEl={anchorEl}
			disablePortal={false}
			transition
		>
			{({ TransitionProps }) => (
				<Fade {...TransitionProps} timeout={350}>
					<div className={`${classes.paper} arrow`}>{children}</div>
				</Fade>
			)}
		</Popper>
	);
}

FbitsPopper.propTypes = {
	anchorEl: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.any]),
	open: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired
};
FbitsPopper.defaultProps = {
	anchorEl: null
};
