import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { FbitsButton } from '../FbitsButton';

import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function FirstAccessReceive({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const location = useLocation();

	const [errorReenvioPrimeiroAcesso, setErrorReenvioPrimeiroAcesso] = useState('');

	const handleReenvioPrimeiroAcesso = () => {
		const query = new URLSearchParams(location.search);

		const param = {
			email: context.emailPrimeiroAcesso,
			returnUrl: query.get('ReturnUrl')
		};

		HeimdallApi.ReenvioPrimeiroAcesso(param)
			.then((response) => {
				if (response.hasError) {
					setErrorReenvioPrimeiroAcesso(response.message);
				} else {
					setErrorReenvioPrimeiroAcesso('');
				}
			})
			.catch(() => {
				setErrorReenvioPrimeiroAcesso('Erro no reenvio do código de primeiro acesso');
			});
	};

	return (
		<div className="slide-left">
			<h1>Primeiro Acesso</h1>
			<p className="mb-15">Enviamos para seu e-mail a chave para registrar sua senha.</p>

			<FbitsButton
				fullWidth
				size="large"
				text="RECEBI A CHAVE"
				onClick={() => nextStep('recoverPasswordConfirm')}
			/>

			<p className="mt-15">
				Não recebeu?
				<span
					className="text-default text-link text-decoration--underline ml-5"
					onClick={handleReenvioPrimeiroAcesso}
				>
					Enviar novamente
				</span>
			</p>

			{errorReenvioPrimeiroAcesso && (
				<Typography style={{ color: '#DC3545', paddingTop: 10 }}>
					{errorReenvioPrimeiroAcesso}
				</Typography>
			)}

			<div className="form__link">
				<button
					type="button"
					onClick={() => nextStep('firstAccessSend')}
					className="text-default text-link link__position link__position--bottom-left"
				>
					VOLTAR
				</button>
			</div>
		</div>
	);
}

FirstAccessReceive.propTypes = {
	nextStep: PropTypes.func.isRequired
};
