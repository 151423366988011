import { Request } from '../utils';
import { ApiSettings } from './ApiSettings';

const BaseUrl = `${ApiSettings.backendUrl}multitenant`;

export class MultitenantApi {
	static GetStores(id) {
		const url = `${BaseUrl}/${id}`;
		return Request.Get(url);
	}

	static Authenticate(data) {
		const url = `${BaseUrl}`;
		return Request.Post(url, data);
	}
}
