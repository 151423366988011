export class Util {
	/**
	 * @param {string} value
	 */
	static PasswordStrength(value) {
		// const strongRegex = new RegExp(
		// 	'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
		// );

		// const mediumRegex = new RegExp(
		// 	'^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
		// );

		const weakness = [];
		// letras minusculas
		if (!/[a-z]/.test(value)) weakness.push('Letra minúsculas');

		// letras maiusculas
		if (!/[A-Z]/.test(value)) weakness.push('Letra maiúscula');

		// números
		if (!/\d/.test(value)) weakness.push('Algum número');

		// caracteres especiais
		if (!/[!@#$%&*()\-_=+<>]/.test(value)) weakness.push('Algum caractere especial');

		// Maior que 8 caracteres
		if (value.length < 8) weakness.push('Mínimo 8 caracteres');

		return weakness;
	}
}
