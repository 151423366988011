import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/autoComplete';
import { FbitsButton } from '../FbitsButton';
import { ApiSettings } from '../../apis/ApiSettings';
import { FbitsLoginContext } from './index';
import { MultitenantApi, LgpdApi } from '../../apis';
import Lgpd from '../../layout/Lgpd';

export function Multitenant({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const location = useLocation();
	const [modalLgpd, setModalLgpd] = useState(false);
	const [userStore, setUserStore] = useState(false);
	const [response, setResponse] = useState({});
	const [stores, setStores] = useState([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const { user, lgpd, setLgpd } = context;

	const aceiteLgpd = () => {
		setModalLgpd(false);

		if (context.lgpd.termo !== null) {
			const data = {
				returnUrl: response.returnUrl,
				loja: response.userStore,
				usuario: response.userName,
				ip: '',
				versoes: context.lgpd.termo.versoes,
			};

			LgpdApi.LgpdAceite(data)
				.then((res) => {
					if (res.value.termoAceito) {
						const url = ApiSettings.backendUrl.replace('/api/', '');
						window.location.href = `${url}${res.value.returnUrl}`;
					}
				})
				.catch((e) => {
					console.log(e);
					console.log('Erro ao aceitar termos da Lei Geral de Proteção de Dados');
				});
		}
	};

	const continuarSemAceitar = () => {
		setModalLgpd(false);
		LgpdApi.ContinuarSemAceitar({ returnUrl: response.returnUrl, loja: response.userStore })
			.then((res) => {
				const url = ApiSettings.backendUrl.replace('/api/', '');
				window.location.href = `${url}${res.value.returnUrl}`;
			})
			.catch(() => {
				console.error('Erro ao concluir login sem aceitar termos lgpd');
			});
	};

	useEffect(() => {
		setLoading(true);

		MultitenantApi.GetStores(user.userId)
			.then((res) => {
				setStores(res);
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				console.error(e, 'Erro ao listar lojas do usuário');
			});
	}, [user.userId]);

	const handleAutenticar = () => {
		setLoading(true);

		const query = new URLSearchParams(location.search);

		const auth = {
			username: user.userLogin,
			password: user.userPass,
			returnUrl: query.get('ReturnUrl'),
			store: userStore,
			searchQueryString: window.location.search,
		};

		MultitenantApi.Authenticate(auth)
			.then((res) => {
				setLgpd({
					user: res.value.userName,
					termo: res.value.termo,
					aceiteStatus: res.value.aceiteStatus,
					isAdmin: res.value.isAdminStore,
				});

				if (!res.value.isAuthenticated) {
					setError(res.value.authenticationMessage);
				} else if (!res.value.aceiteStatus) {
					setResponse({
						returnUrl: res.value.returnUrl,
						userName: res.value.userName,
						userStore: res.value.store,
					});
					setModalLgpd(true);
				} else if (!res.value.profileCompleted) {
					nextStep('profileComplete');
				} else {
					const url = ApiSettings.backendUrl.replace('/api/', '');
					window.location.href = `${url}${res.value.returnUrl}`;
				}
			})
			.catch((e) => {
				setError(e?.error?.value?.authenticationMessage ?? 'Erro ao realizar autenticação! Por favor, confira seus dados e tente novamente.');
				console.error(e);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			console.log('loja');
		}
	};

	return (
		<div className="slide-left">
			<h1>Escolha uma loja</h1>

			<form noValidate autoComplete="off">
				<Autocomplete
					id="loja"
					loading={loading}
					loadingText="carregando"
					options={stores}
					getOptionLabel={(option) => option.name}
					onChange={(e, value) => setUserStore(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							required
							autoFocus
							label="Lojas"
							variant="outlined"
							placeholder="selecione uma loja"
							name="loja"
							className="mb-15"
							onKeyPress={handleKeyPress}
						/>
					)}
				/>

				<FbitsButton
					fullWidth
					disabled={loading}
					text={!loading ? `Entrar com ${(userStore && userStore.name) || '...'}` : 'Carregando...'}
					size="large"
					onClick={handleAutenticar}
				/>
			</form>

			<Typography color="error" style={{ color: '#DC3545', paddingBottom: 10, paddingLeft: 5, paddingTop: '10px' }}>
				{error}
			</Typography>

			<div className="form__link">
				<button onClick={() => nextStep('login')} type="button" className="text-default text-link link__position link__position--bottom-left">
					VOLTAR
				</button>
			</div>

			{modalLgpd ? (
				<Lgpd
					open={modalLgpd}
					accept={aceiteLgpd}
					data={lgpd.termo}
					continuar={continuarSemAceitar}
					exibirTermo={lgpd?.isAdmin || false}
					close={() => setModalLgpd(false)}
				/>
			) : null}
		</div>
	);
}

Multitenant.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
