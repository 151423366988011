import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export function FbitsTooltip({ placement, title, children }) {
	const SomeContent = React.forwardRef((props, ref) => (
		<span {...props} ref={ref}>
			{children}
		</span>
	));

	return (
		<BootstrapTooltip placement={placement} title={title}>
			<SomeContent />
		</BootstrapTooltip>
	);
}

FbitsTooltip.propTypes = {
	children: PropTypes.element.isRequired,
	title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired,
	placement: PropTypes.oneOf([
		'bottom-end',
		'bottom-start',
		'bottom',
		'left-end',
		'left-start',
		'left',
		'right-end',
		'right-start',
		'right',
		'top-end',
		'top-start',
		'top'
	]).isRequired
};

const useStylesBootstrap = makeStyles(() => ({
	arrow: {
		color: 'rgba(52,58,64,0.8)'
	},

	tooltip: {
		zIndex: -1,
		backgroundColor: 'rgba(52, 58, 64, 0.9)',
		letterSpacing: 1,
		padding: 10,
		position: 'relative',
		textAlign: 'left',
		fontSize: 14
	},

	popper: {
		zIndex: 1501
	}
}));

function BootstrapTooltip(props) {
	const classes = useStylesBootstrap();
	return <Tooltip arrow classes={classes} {...props} />;
}
