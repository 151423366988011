/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button, Slide, Checkbox, FormControlLabel } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function Lgpd({ open, accept, data, continuar, exibirTermo, close }) {
	const convertHTML = (str) => {
		return { __html: str };
	};

	const dataBloqueio = new Date(data.dataInicioBloqueio);
	const dataHoje = new Date();
	const forcarAceite = dataHoje > dataBloqueio;
	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="md"
			TransitionComponent={Transition}
			keepMounted
			disableBackdropClick
			disableEscapeKeyDown
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<div className="lgpd">
				{exibirTermo ? (
					<>
						<h1 className="lgpd-title">{data.nome}</h1>
						<div className="scroll" dangerouslySetInnerHTML={convertHTML(data.texto)} />
						<ul>
							{data.versoes.map((x) => (
								<li key={x.politicaVersaoId}>
									<FormControlLabel
										control={<Checkbox checked name={x.politicaVersaoId} />}
										label={x.nome}
									/>
									(
									<a href={x.link} target="_target">
										Link
									</a>
									)
								</li>
							))}
						</ul>
						<p>
							Somente Administradores da loja podem aceitar os termos do Termo de
							Aceite Lei Geral de Proteção de Dados (LGPD)
						</p>

						{!forcarAceite ? (
							<>
								<p>
									Você tem até o dia{' '}
									<strong>{dataBloqueio.toLocaleDateString('pt-BR')}</strong> para
									aceitar o termo, após essa data o acesso ao painel será
									permitido somente após a aceitação.
								</p>
								<Button
									className="float-left mt-15"
									onClick={continuar}
									variant="contained"
									color="default"
								>
									Continuar
								</Button>
							</>
						) : null}
						<Button
							className="float-right mt-15"
							onClick={accept}
							variant="contained"
							color="primary"
						>
							Aceito
						</Button>
					</>
				) : (
					<>
						{forcarAceite ? (
							<h1 className="lgpd-title">Acesso temporariamente bloqueado</h1>
						) : (
							<h1 className="lgpd-title">Aviso termos LGPD</h1>
						)}

						<div className="scroll">
							<p>
								Devido a nova Lei Geral de Proteção de Dados - LGPD, é necessário
								que um administrador da loja leia e aceite os termos e condições.{' '}
							</p>

							{!forcarAceite ? (
								<>
									<p>
										O prazo máximo para aceitar os termos e condições é{' '}
										<strong>{dataBloqueio.toLocaleDateString('pt-BR')}</strong>,
										após essa data o acesso ao painel será permitido somente
										após a aceitação.
									</p>
									<Button
										className="float-right mt-15"
										onClick={continuar}
										variant="contained"
										color="default"
									>
										CONTINUAR
									</Button>
								</>
							) : (
								<>
									<p>
										Entre em contato com um administrador para que seu acesso
										seja liberado.
									</p>
									<Button
										className="float-right mt-15"
										onClick={close}
										variant="contained"
										color="primary"
									>
										ENTENDI
									</Button>
								</>
							)}
						</div>
					</>
				)}
			</div>
		</Dialog>
	);
}

const data = {
	nome: PropTypes.string,
	texto: PropTypes.string,
	// politicaId: PropTypes.number,
	statusAceite: PropTypes.bool,
	versoes: PropTypes.objectOf({
		politicaVersaoId: PropTypes.number,
		nome: PropTypes.string,
		link: PropTypes.string
	})
};

Lgpd.propTypes = {
	open: PropTypes.bool.isRequired,
	exibirTermo: PropTypes.bool.isRequired,
	accept: PropTypes.func.isRequired,
	continuar: PropTypes.func.isRequired,
	data: PropTypes.objectOf(PropTypes.shape(data))
};

Lgpd.defaultProps = {
	data: {
		texto: '',
		nome: '',
		// politicaId: 1,
		statusAceite: false,
		versoes: {}
	}
};
