export class Request {
	/**
	 * @param {string} url
	 * @param {object} data
	 * @param {object} headers
	 */
	static Get(url, data, headers = {}) {
		url += Request.BuildQueryString(data);
		return _makeRequest(url, { method: 'GET', headers });
	}

	/**
	 * @param {string} url
	 * @param {object} data
	 * @param {object} headers
	 */
	static Post(url, data, headers = {}) {
		return _makeRequest(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json, text/html', ...headers }
		});
	}

	/**
	 * @param {string} url
	 * @param {object} data
	 * @param {object} headers
	 */
	static Put(url, data, headers = {}) {
		return _makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: { 'Content-Type': 'application/json, text/html', ...headers }
		});
	}

	/**
	 * @param {string} url
	 * @param {object} headers
	 */
	static Delete(url, headers = {}) {
		return _makeRequest(url, { method: 'DELETE', headers });
	}

	/**
	 * @param {string} data
	 * @param {string} char
	 */
	static BuildQueryString(data, char = '?') {
		if (data && Object.keys(data).length > 0) {
			let params = [];
			for (var key in data) {
				const value = data[key];
				if (!isNull(value)) {
					if (Array.isArray(value)) {
						// eslint-disable-next-line
                        params = [...params, ...value.map(x => `${key}=${x}`)];
					} else {
						params.push(`${key}=${value}`);
					}
				}
			}
			return char + params.join('&');
		}
		return '';
	}
}

let _makeRequest = (url, config) => {
	return new Promise((resolve, reject) => {
		let resp;
		fetch(url, config)
			.then((response) => {
				if (response.status === 204) {
					// no content
					resolve();
				}
				resp = response;
				return response.text();
			})
			.then((text) => {
				if (resp.status && resp.status >= 200 && resp.status < 300) {
					resolve(JSON.parse(text), resp.status);
				}
				reject({ resp, error: text ? JSON.parse(text) : '' });
			})
			.catch(reject);
	});
};

let isNull = (obj) => obj === null || obj === undefined;
