export class Storage {
	/**
	 * @param {string} key
	 * @param {object} value
	 */
	static set(key, value) {
		localStorage.setItem(key, JSON.stringify(value));
	}

	/**
	 * @param {string} key
	 */
	static contains(key) {
		return !!localStorage.getItem(key);
	}

	/**
	 * @param {string} key
	 */
	static get(key) {
		const item = localStorage.getItem(key);
		if (item) return JSON.parse(item);
		return undefined;
	}

	/**
	 * @param {string} key
	 */
	static delete(key) {
		localStorage.removeItem(key);
	}

	/**
	 * @param {string} key
	 */
	static pop(key) {
		const item = Storage.get(key);
		Storage.delete(key);
		return item;
	}
}
