import React, { useState, useCallback, useContext } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { FbitsButton } from '../FbitsButton';
import MaskedInput from 'react-text-mask';
import styles from './styles.module.scss';
import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function ProfileComplete({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const [phone, setPhone] = useState('');
	const [formError, setFormError] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const { user, returnUrl, isMultitenant } = context;
	const location = useLocation();
	const query = new URLSearchParams(location.search);

	const handleComplete = useCallback(() => {
		if (phone?.length >= 11 && phone?.length < 16) {
			setLoading(true);
			setError('');
			setFormError(false);
			HeimdallApi.ProfileComplete({ telefoneCelular: phone, returnUrl: query.get('ReturnUrl'), storeNotFound: isMultitenant })
				.then((res) => {
					setLoading(false);
					if (!res?.result) setError('Não foi possível atualizar seu perfil! Verifique a informação preenchida e tente novamente .');
					else {
						loginComplete();
					}
				})
				.catch((ex) => setError('Ocorreu um erro ao atualizar seu perfil! Verifique a informação preenchida e tente novamente.'));
		} else {
			setFormError(true);
			setError('');
		}
	}, [setError, setFormError, setLoading, phone, user, returnUrl]);

	const loginComplete = useCallback(() => {
		HeimdallApi.LoginComplete({ returnUrl: query.get('ReturnUrl'), storeNotFound: isMultitenant })
			.then((res) => {
				if (res?.value?.storeNotFound && res?.value?.success) {
					nextStep('multitenant');
				} else if (res?.value?.success) window.location.href = returnUrl;
				else setError('Não foi possível completar login! Verifique seu usuário e tente novamente.');
			})
			.catch((ex) => {
				setError('Ocorreu um problema ao completar o login! Verifique seu usuário e tente novamente.');
			});
	}, [setError, returnUrl, isMultitenant]);

	const handleKeyPress = useCallback(
		(event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				handleComplete();
			}
		},
		[handleComplete],
	);

	return (
		<div className="slide-left">
			<h1>Olá {user?.nome || 'Usuário'}</h1>

			<form noValidate>
				<Typography className={`${styles.text} mb-20`}>Identificamos que os dados do seu usuário encontram-se incompletos!</Typography>
				<Typography className={`${styles.text} mb-24`}>Complete seu cadastro inserindo no campo abaixo o seu telefone de contato.</Typography>

				<TextField
					fullWidth
					required
					autoFocus
					error={formError}
					helperText={formError ? 'Telefone inválido' : null}
					variant="outlined"
					className="mb-15"
					placeholder="(00) 00000-0000"
					label="Telefone"
					name="autenticador"
					id="authentication"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					onKeyPress={handleKeyPress}
					InputProps={{
						inputComponent: TextMaskCustom,
					}}
				/>

				{error ? (
					<Typography color="error" className="mb-24">
						{error}
					</Typography>
				) : null}

				<FbitsButton
					fullWidth
					disabled={loading}
					text={loading ? 'Aguarde...' : 'Cadastrar'}
					size="large"
					onClick={handleComplete}
					className={`${styles.buttonComplete} mb-8`}
				/>
				{error ? null : <Typography color="error">A Wake Commerce jamais irá pedir sua senha, caso seja solicitado, não informe.</Typography>}

				<FbitsButton
					className={`${styles.buttonIgnore} mt-24`}
					fullWidth
					disabled={loading}
					color="inherit"
					text="Lembrar mais tarde"
					size="large"
					onClick={loginComplete}
				/>
			</form>
		</div>
	);
}

const TextMaskCustom = ({ inputRef, ...other }) => {
	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	);
};

ProfileComplete.propTypes = {};

ProfileComplete.defaultProps = {};
