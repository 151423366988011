import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export function InputCheckbox({ checked, onChange, id, label, disabled }) {
	return (
		<FormControlLabel
			control={
				<Checkbox checked={checked} onChange={onChange} name={id} disabled={disabled} />
			}
			label={label}
		/>
	);
}

InputCheckbox.propTypes = {
	label: PropTypes.string,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};

InputCheckbox.defaultProps = {
	label: '',
	id: '',
	checked: false,
	disabled: false
};
