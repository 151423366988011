import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import { FbitsButton } from '../FbitsButton';

import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function RecoverPasswordSend({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const location = useLocation();

	const [email, setEmail] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	function enviarCodigo() {
		setLoading(true);

		const query = new URLSearchParams(location.search);

		const recuperarSenha = {
			email,
			returnUrl: query.get('ReturnUrl'),
			redirectUri: query.get('RedirectUri'),
		};

		HeimdallApi.RecuperarSenha(recuperarSenha)
			.then((response) => {
				if (response.hasError) {
					setError(response.message);
				} else {
					context.setEmailRecuperarSenha(response.email);
					nextStep('recoverPasswordReceive');
				}

				setLoading(false);
			})
			.catch((e) => {
				console.error(e);
				if (e?.resp?.status == 429) {
					setError('Limite de solicitações atingido');
				} else {
					setError('Erro ao enviar código de recuperação de senha');
				}
				setLoading(false);
			});
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			enviarCodigo();
		}
	};

	return (
		<div className="slide-left">
			<h1>Recuperar senha</h1>
			<p className="mb-15">Insira seu e-mail para alterações de suas informações de login:</p>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					error={Boolean(error)}
					helperText={error}
					variant="outlined"
					className="mb-15"
					placeholder="seuemail@exemplo.com.br"
					label="E-mail"
					type="email"
					name="firstAccess"
					id="firstAccess"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyPress={handleKeyPress}
				/>

				<FbitsButton fullWidth text={!loading ? 'Enviar' : 'Carregado...'} disabled={loading} size="large" onClick={enviarCodigo} />
			</form>

			<div className="form__link">
				<button type="button" onClick={() => nextStep('login')} className="text-default text-link link__position link__position--bottom-left">
					VOLTAR
				</button>
			</div>
		</div>
	);
}

RecoverPasswordSend.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
