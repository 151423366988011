import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		type: 'light',
		primary: {
			light: '#4BB5FC',
			main: '#18A0FB',
			dark: '#18A0FB',
			contrastText: '#FFFFFF',
		},
		secondary: {
			light: '#575757',
			main: '#1A1A1A',
			dark: '#0D0D0D',
			contrastText: '#FFFFFF',
		},
		text: {
			primary: '#17191C',
			secondary: '#444C55',
			disabled: '#B8BFC6',
		},
		error: {
			light: '#E05252',
			main: '#D92626',
			dark: '#AD1F1F',
			contrastText: '#FFFFFF',
		},
		warning: {
			light: '#EDAD5E',
			main: '#E89530',
			dark: '#CF7C17',
			contrastText: '#FFFFFF',
		},
		info: {
			light: '#4BB5FC',
			main: '#18A0FB',
			dark: '#0486DD',
			contrastText: '#FFFFFF',
		},
		success: {
			light: '#5CD65C',
			main: '#29A329',
			dark: '#1F7A1F',
			contrastText: '#FFFFFF',
		},
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
	overrides: {
		MuiOutlinedInput: {
			root: {
				height: 50,
				'&:hover $notchedOutline': {
					borderColor: '#18A0FB',
				},

				'& $notchedOutline': {
					borderColor: '#A4A4A4',
				},
				'&$focused $notchedOutline': {
					borderColor: '#18A0FB',
				},
			},
		},
		MuiTypography: {
			root: {
				// color: '#333333 !important',
			},
			colorError: {
				color: '#D92626 !important',
			},
		},
		MuiCheckbox: {
			colorSecondary: {
				'&$checked': {
					color: '#18A0FB',
				},
			},
		},
	},
});

export default theme;
