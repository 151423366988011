import React, { useState, useEffect, createContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { RecoverPasswordSend } from './RecoverPasswordSend';
import { RecoverPasswordReceive } from './RecoverPasswordReceive';
import { RecoverPasswordConfirm } from './RecoverPasswordConfirm';
import { FirstAccessSend } from './FirstAccessSend';
import { FirstAccessReceive } from './FirstAccessReceive';
import { FirstAccessConfirm } from './FirstAccessConfirm';
import { Authentication } from './Authentication';
import { Multitenant } from './Multitenant';
import { AskConsent } from './AskConsent';
import { Login } from './Login';
import { ProfileComplete } from './ProfileComplete';

export const FbitsLoginContext = createContext({});

export function FbitsLogin() {
	const location = useLocation();

	const [step, setStep] = useState('login');
	const [type2FA, setType2FA] = useState('');
	const [isMultitenant, setIsMultitenant] = useState(false);
	const [emailValidacao, setEmailValidacao] = useState('');
	const [lgpd, setLgpd] = useState({ user: '', aceiteStatus: false, termo: {} });
	const [user, setUser] = useState({ userName: '', userId: 0, userLogin: '', userPass: '', nome: '' });
	const [emailRecuperarSenha, setEmailRecuperarSenha] = useState('');
	const [emailPrimeiroAcesso, setEmailPrimeiroAcesso] = useState('');
	const [chaveRecuperarSenha, setChaveRecuperarSenha] = useState('');
	const [returnUrl, setReturnUrl] = useState('');

	const handleNext = useCallback(
		(value) => {
			setStep(value);
		},
		[setStep],
	);

	const query = new URLSearchParams(location.search);
	const chave = query.get('ChaveRecuperarSenha');

	useEffect(() => {
		if (window.location.pathname === '/Account/Consent') {
			setStep('askConsent');
		} else if (chave) {
			setChaveRecuperarSenha(chave);
			setStep('recoverPasswordConfirm');
		}
	}, [location, chave]);

	const stepComponents = (id) => {
		switch (id) {
			case 'authentication':
				return <Authentication nextStep={handleNext} />;
			case 'profileComplete':
				return <ProfileComplete nextStep={handleNext} />;
			case 'recoverPasswordSend':
				return <RecoverPasswordSend nextStep={handleNext} />;
			case 'recoverPasswordReceive':
				return <RecoverPasswordReceive nextStep={handleNext} />;
			case 'recoverPasswordConfirm':
				return <RecoverPasswordConfirm nextStep={handleNext} />;
			case 'firstAccessSend':
				return <FirstAccessSend nextStep={handleNext} />;
			case 'firstAccessReceive':
				return <FirstAccessReceive nextStep={handleNext} />;
			case 'firstAccessConfirm':
				return <FirstAccessConfirm nextStep={handleNext} />;
			case 'askConsent':
				return <AskConsent nextStep={handleNext} />;
			case 'multitenant':
				return <Multitenant nextStep={handleNext} />;
			default:
				return <Login nextStep={handleNext} />;
		}
	};

	return (
		<Paper className="box box__form mb-50" style={{ overflow: 'hidden' }}>
			<FbitsLoginContext.Provider
				value={{
					emailValidacao,
					setEmailValidacao,
					type2FA,
					setType2FA,
					lgpd,
					setLgpd,
					emailRecuperarSenha,
					setEmailRecuperarSenha,
					emailPrimeiroAcesso,
					setEmailPrimeiroAcesso,
					chaveRecuperarSenha,
					user,
					setUser,
					isMultitenant,
					setIsMultitenant,
					returnUrl,
					setReturnUrl,
				}}
			>
				{stepComponents(step)}
			</FbitsLoginContext.Provider>
		</Paper>
	);
}
