import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import { Typography } from '@material-ui/core';
import { FbitsButton } from '../FbitsButton';
import { FbitsLoginContext } from './index';
import { HeimdallApi } from '../../apis';

export function RecoverPasswordReceive({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const location = useLocation();

	const [errorReenvioRecuperarSenha, setErrorReenvioRecuperarSenha] = useState('');

	const handleReenvioRecuperarSenha = () => {
		const query = new URLSearchParams(location.search);

		const param = {
			email: context.emailRecuperarSenha,
			returnUrl: query.get("ReturnUrl")
		};

		HeimdallApi.ReenvioRecuperarSenha(param)
			.then(response => {
				if (response.hasError) {
					setErrorReenvioRecuperarSenha(response.message);
				} else {
					setErrorReenvioRecuperarSenha('');
				}
			})
			.catch((e) => {
				console.error(e);
				if (e?.resp?.status == 429) {
					setErrorReenvioRecuperarSenha('Limite de solicitações atingido');
				} else {
					setErrorReenvioRecuperarSenha('Erro no reenvio do código de recuperação de senha');
				}
			});
	}

	return (
		<div className="slide-left">
			<h1>Recuperar senha</h1>
			<p className="mb-15">Enviamos para seu e-mail a chave para recuperação de senha.</p>

			<FbitsButton
				fullWidth
				size="large"
				text="RECEBI A CHAVE"
				onClick={() => nextStep('recoverPasswordConfirm')}
			/>

			<p className="mt-15">
				Não recebeu? 
				<span className="text-default text-link text-decoration--underline ml-5" onClick={handleReenvioRecuperarSenha}>
					Enviar novamente
				</span>
			</p>

			{errorReenvioRecuperarSenha &&
				<Typography style={{ color: '#DC3545', paddingTop: 10 }}>
					{errorReenvioRecuperarSenha}
				</Typography>
			}

			<div className="form__link">
				<button
					type="button"
					onClick={() => nextStep('recoverPasswordSend')}
					className="text-default text-link link__position link__position--bottom-left"
				>
					VOLTAR
				</button>
			</div>
		</div>
	);
}

RecoverPasswordReceive.propTypes = {
	nextStep: PropTypes.func.isRequired
};
