import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { FbitsButton } from '../FbitsButton';
import { ApiSettings } from '../../apis/ApiSettings';
import { FbitsLoginContext } from './index';
import { HeimdallApi, LgpdApi } from '../../apis';
import Lgpd from '../../layout/Lgpd';

export function Authentication({ nextStep }) {
	const context = useContext(FbitsLoginContext);
	const location = useLocation();
	const [authentication, setAuthentication] = useState('');
	const [textAuthentication, setTextAuthentication] = useState('');
	const [modalLgpd, setModalLgpd] = useState(false);
	const [response, setResponse] = useState({});
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);

	const [messageReenvioCodigoAcesso, setMessageReenvioCodigoAcesso] = useState({
		text: '',
		type: '',
	});

	const { emailValidacao, type2FA, isMultitenant, user } = context;

	const emailValida = useMemo(() => {
		const caracteres = emailValidacao.split('@');
		return `${caracteres[0].substring(0, 3)}****@${caracteres[1]}`;
	}, [emailValidacao]);

	useEffect(() => {
		switch (type2FA) {
			case 2:
				setTextAuthentication(`Utilize o App de sua escolha e insira o código gerado no campo abaixo:`);
				break;
			case 3:
				setTextAuthentication(`Confira SMS o código que enviamos e insira no campo abaixo:`);
				break;

			default:
				setTextAuthentication(`Confira em seu e-mail (${emailValida}) o código e insira no campo abaixo: `);
				break;
		}
	}, [type2FA, emailValida]);

	const aceiteLgpd = () => {
		setModalLgpd(false);

		if (context.lgpd.termo !== null) {
			const data = {
				returnUrl: response.returnUrl,
				loja: response.userStore,
				usuario: response.userName,
				ip: '',
				versoes: context.lgpd.termo.versoes,
			};

			LgpdApi.LgpdAceite(data)
				.then((res) => {
					if (res.value.termoAceito) {
						const url = ApiSettings.backendUrl.replace('/api/', '');
						window.location.href = `${url}${res.value.returnUrl}`;
					}
				})
				.catch((e) => {
					console.log('Erro ao aceitar termos da Lei Geral de Proteção de Dados');
				});
		}
	};

	const continuarSemAceitar = () => {
		setModalLgpd(false);
		LgpdApi.ContinuarSemAceitar({ returnUrl: response.returnUrl, loja: response.userStore })
			.then((res) => {
				const url = ApiSettings.backendUrl.replace('/api/', '');
				window.location.href = `${url}${res.value.returnUrl}`;
			})
			.catch(() => {
				console.error('Erro ao concluir login sem aceitar termos lgpd');
			});
	};

	const handleValidar = () => {
		setLoading(true);

		const query = new URLSearchParams(location.search);

		const authenticationParam = {
			accessCodeNumber: parseInt(authentication, 10),
			storeNotFound: isMultitenant,
			userId: user.userId,
			returnUrl: query.get('ReturnUrl'),
			searchQueryString: window.location.search,
		};

		HeimdallApi.ValidateAccessCode(authenticationParam)
			.then((res) => {
				if (!res.value.isValid) {
					setError(res.value.validationMessage);
					setIsBlocked(res.value.isBlocked);
				} else if (res.value.storeNotFound) {
					nextStep('multitenant');
				} else if (!res.value.profileCompleted) {
					nextStep('profileComplete');
				} else if (res.value.aceiteStatus) {
					const url = ApiSettings.backendUrl.replace('/api/', '');
					window.location.href = `${url}${res.value.returnUrl}`;
				} else {
					setResponse({
						returnUrl: res.value.returnUrl,
						userName: res.value.userName,
						userStore: res.value.store,
					});
					setModalLgpd(true);
				}

				setLoading(false);
			})
			.catch((e) => {
				setError('Erro na verificação do código' || e);
				setLoading(false);
			});
	};

	const handleReenvioCodigoAcesso = ({ isSms }) => {
		const query = new URLSearchParams(location.search);

		const param = {
			userName: (context.user && context.user.userName) || '',
			isSms,
			email: emailValidacao,
			returnUrl: query.get('ReturnUrl'),
		};

		HeimdallApi.ReenvioCodigoAcesso(param)
			.then((res) => {
				if (res.hasError) {
					setMessageReenvioCodigoAcesso({ text: response.message, type: '#DC3545' });
				} else {
					setMessageReenvioCodigoAcesso({
						text: `Seu código de acesso foi encaminhado ${isSms ? ' via SMS.' : 'para seu e-mail'}`,
						type: '#42B847',
					});
				}
			})
			.catch(() => {
				setMessageReenvioCodigoAcesso({
					text: 'Erro no reenvio do código de acesso',
					type: '#DC3545',
				});
			});
	};

	const handleKeyPress = useCallback(
		(event) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				handleValidar();
			}
		},
		[handleValidar],
	);

	return (
		<div className="slide-left">
			<h1>Autenticação em dois fatores</h1>
			<p className="mb-15">{textAuthentication}</p>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					autoFocus
					error={error.length > 0}
					helperText={error}
					variant="outlined"
					className="mb-15"
					placeholder="XXXXXX"
					label="Autenticação"
					name="autenticador"
					id="authentication"
					value={authentication}
					onChange={(e) => setAuthentication(e.target.value)}
					onKeyPress={handleKeyPress}
				/>

				<FbitsButton fullWidth disabled={loading || isBlocked} text={loading ? 'Carregando...' : 'Validar Código'} size="large" onClick={handleValidar} />
			</form>

			{type2FA !== 2 ? (
				<p className="mt-15">
					Não recebeu?{' '}
					<button
						type="button"
						aria-label="Receber código de acesso por SMS"
						className="text-default text-link text-decoration--underline"
						style={{ backgroundColor: '#fff' }}
						onClick={() => handleReenvioCodigoAcesso({ isSms: false })}
					>
						Enviar novamente por email
					</button>{' '}
				</p>
			) : null}

			{messageReenvioCodigoAcesso.text && (
				<Typography style={{ color: messageReenvioCodigoAcesso.type, paddingTop: 10 }}>{messageReenvioCodigoAcesso.text}</Typography>
			)}

			<div className="form__link">
				<button onClick={() => nextStep('login')} type="button" className="text-default text-link link__position link__position--bottom-left">
					VOLTAR
				</button>
			</div>

			{modalLgpd ? (
				<Lgpd
					open={modalLgpd}
					accept={aceiteLgpd}
					data={context.lgpd.termo}
					continuar={continuarSemAceitar}
					exibirTermo={context?.lgpd?.isAdmin || false}
					close={() => setModalLgpd(false)}
				/>
			) : null}
		</div>
	);
}

Authentication.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
