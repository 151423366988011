import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { FbitsButton, InputPassword, FbitsTooltip, FbitsPopper } from '../index';
import { Util } from '../../utils';

export function FirstAccessConfirm({ nextStep }) {
	const [values, setValues] = useState({
		chave: '',
		senha: '',
		confirmarSenha: ''
	});
	const [error, setError] = useState(false);
	const [strength, setStrength] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleFormFirstAccess = (name) => (event) => {
		const password = event.target.value;
		const rules = event.currentTarget;
		const strengthLength = strength.length;

		setStrength(Util.PasswordStrength(password));
		setValues({ ...values, [name]: password });

		if (strengthLength > 0) {
			setAnchorEl(rules);
		} else {
			setAnchorEl(null);
		}
	};

	const handleForm = (name) => (event) => {
		const text = event.target.value;
		const rules = event.currentTarget;
		setValues({ ...values, [name]: text });

		if (values.senha !== text && name === 'confirmarSenha') {
			setError(true);
			setAnchorEl(rules);
		} else {
			setError(false);
			setAnchorEl(null);
		}
	};

	return (
		<div className="slide-left">
			<h1>
				Primeiro acesso
				<div className="float-right">
					<FbitsTooltip
						title={
							<ul>
								<li className="mb-5">
									<b>Sua senha deve ter</b>:
								</li>
								<li>- letra minúscula (a-z)</li>
								<li>- letra maiúscula (A-Z)</li>
								<li>- números (0-9)</li>
								<li>- caracteres especiais (!@#$)</li>
								<li>- mínimo de 8 caracteres</li>
							</ul>
						}
						placement="right-start"
						arrow
					>
						<Info style={{ color: '#59667B' }} />
					</FbitsTooltip>
				</div>
			</h1>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					variant="outlined"
					className="mb-15"
					placeholder="XXXXXXXXXXX"
					label="Chave"
					name="chave"
					id="chave"
					value={values.chave}
					onChange={handleForm('chave')}
				/>

				<InputPassword
					placeholder="Digite sua senha"
					name="senha"
					value={values.senha}
					onChange={handleFormFirstAccess('senha')}
					label="Senha"
					labelWidth={50}
					className="mb-15"
					error={strength.length > 0}
					aria-describedby="senha"
				/>

				<FbitsPopper open={Boolean(anchorEl) && strength.length > 0} anchorEl={anchorEl}>
					<b>Insira pelo menos uma:</b>
					{strength.map((x) => (
						<span key={x} className="d-block">
							- {x}
						</span>
					))}
				</FbitsPopper>

				<InputPassword
					placeholder="Confirme sua senha"
					name="confirmarSenha"
					value={values.confirmarSenha}
					onChange={handleForm('confirmarSenha')}
					label="Confirmar Senha"
					labelWidth={120}
					className="mb-15"
					error={error}
				/>

				<FbitsPopper open={error} anchorEl={anchorEl}>
					Senhas são diferentes
				</FbitsPopper>

				<FbitsButton
					fullWidth
					size="large"
					text="Alterar"
					onClick={() => nextStep('login')}
				/>
			</form>

			<div className="form__link">
				<button
					onClick={() => nextStep('FirstAccessReceive')}
					type="button"
					className="text-default text-link link__position link__position--bottom-left"
				>
					VOLTAR
				</button>
			</div>
		</div>
	);
}

FirstAccessConfirm.propTypes = {
	nextStep: PropTypes.func.isRequired
};
