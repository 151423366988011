import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import { Grid } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from './layout/Theme';
import { Login, Home, NotFound, Error, Unlock, AccessDenied } from './layout';
import { Warning } from './components/Warning';
import Footer from './layout/Footer';
import { HeimdallSettings } from './HeimdallSettings';
import { EnumEnvironment } from './apis/EnumEnvironment';
import './styles/style.scss';
import { unregister } from './registerServiceWorker';

const rootElement = document.getElementById('root');
const history = createBrowserHistory();
const stringParams = history.location.search;
const paramsSearch = new URLSearchParams(stringParams);

function popup() {
	const isPopUp = paramsSearch.get('popup');

	if (isPopUp === 'true') {
		return true;
	}

	return false;
}

ReactDOM.render(
	<Router history={history}>
		<div
			className="layout_bg"
			style={{
				backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg-screen-login.svg)`,
			}}
		>
			<MuiThemeProvider theme={Theme}>
				<Grid container direction="row" justifyContent="center" alignItems="center" className="box__full-width">
					<Grid item xs={12} md={6}>
						<img
							src={`${process.env.PUBLIC_URL}/images/wake.svg`}
							alt="WakeCommerce"
							title="WakeCommerce"
							className="logo mt-50"
							width="180"
							height="55"
							loading="lazy"
						/>

						<Switch>
							<Route path="/Account/Login">
								<Login />
							</Route>
							<Route exact path="/Recuperar">
								<Login />
							</Route>
							<Route path="/Account/Unlock">
								<Unlock />
							</Route>
							<Route path="/Account/Error">
								<Error />
							</Route>
							<Route path="/Account/AccessDenied">
								<AccessDenied />
							</Route>
							<Route path="/Account/Consent">
								<Login />
							</Route>
							<Route exact path="/loja-nao-encontrada">
								<Home />
							</Route>
							<Route exact path="/">
								<Home />
							</Route>
							<Route path="*">
								<NotFound />
							</Route>
						</Switch>
					</Grid>

					{popup() ? null : (
						<>
							<Grid item xs={12} md={6}>
								<Warning />
							</Grid>

							<Grid item xs={12}>
								<Footer />
							</Grid>
						</>
					)}
				</Grid>
			</MuiThemeProvider>
		</div>
	</Router>,
	rootElement,
);

if (HeimdallSettings.environment === EnumEnvironment.PRODUCTION) {
	ReactGA.initialize('UA-138525215-1');
	hotjar.initialize(1275384, 6);
	history.listen((location) => {
		ReactGA.pageview(location.pathname + location.search);
	});
}

unregister();
