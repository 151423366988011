import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { ListItemText, ListItem, List, ListItemIcon } from '@material-ui/core';
import { FiberManualRecordRounded } from '@material-ui/icons';
import { ApiSettings } from '../../apis/ApiSettings';
import { FbitsButton } from '../FbitsButton';
import { ConsentApi } from '../../apis';

export function AskConsent({ nextStep }) {
	const [finishedScrolling, setFinishedScrolling] = useState(true);
	const [consent, setConsent] = useState({});
	const returnUrl = window.location.search;

	useEffect(() => {
		ConsentApi.Get(returnUrl)
			.then((data) => {
				if (data) {
					setConsent(data);
				}
			})
			.catch((ex) => {
				console.error(ex);
			});
	}, [returnUrl]);

	const handleScroll = (values) => {
		if (values.top === 1) setFinishedScrolling(true);
	};
	const handlePermitirAcesso = () => {
		ConsentApi.GrantAccess({ returnUrl }).then((data) => {
			if (data && data.returnUrl) {
				const url = ApiSettings.backendUrl.replace('/api/', '');
				window.location.href = `${url}${data.returnUrl}`;
			}
		});
	};

	if (Object.keys(consent).length === 0) {
		return null;
	}

	return (
		<>
			<h1>Permitir Acesso</h1>
			<p>
				<strong>{consent.clientName}</strong> gostaria de acessar as seguintes informações:
			</p>
			<Scrollbars
				autoHeight
				autoHeightMin={100}
				autoHeightMax={200}
				hideTracksWhenNotNeeded
				onScrollFrame={handleScroll}
				renderView={(props) => <div {...props} className="scrollbar-view" />}
			>
				<List component="ul">
					{consent.identityScopes &&
						consent.identityScopes.map((identity) => (
							<ListItem component="li" alignItems="flex-start">
								<ListItemIcon>
									<FiberManualRecordRounded style={{ color: '#C7C7C7' }} />
								</ListItemIcon>
								<ListItemText primary={identity.displayName} secondary={identity.description} />
							</ListItem>
						))}
				</List>
			</Scrollbars>
			<FbitsButton fullWidth text="Permitir Acesso" size="large" disabled={!finishedScrolling} onClick={handlePermitirAcesso} />
		</>
	);
}

AskConsent.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
