import { EnumEnvironment } from './apis/EnumEnvironment';

export const HeimdallSettings = {
	socialMedia: {
		blog: 'https://www.traycorp.com.br/conteudo/',
		facebook: 'https://www.facebook.com/traytecnologia',
		linkedin: 'https://www.linkedin.com/company/tray-corp/',
		twitter: 'https://twitter.com/Tray_Ecommerce',
		youtube: 'https://www.youtube.com/user/marketingfbits',
	},
	helpCenter: {
		ajuda: 'https://wake.octadesk.com/kb',
		canaisAtendimento: 'https://wakecommerce.zendesk.com/hc/pt-br',
		telAtendimentoUrl: 'tel:+551128530696',
		telAtendimentoNumber: '(11) 2853-0696',
	},

	environment: ['localhost:44311', 'localhost:5001'].includes(window.location.host)
		? EnumEnvironment.DEBUG
		: window.location.host === 'accounts-hlog.fbits.net'
		? EnumEnvironment.HLOG
		: EnumEnvironment.PRODUCTION,
};
