import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Paper } from '@material-ui/core';
import { FbitsButton } from '../components/FbitsButton';
import { HeimdallApi } from '../apis';
import { HeimdallSettings } from '../HeimdallSettings';

export function Unlock() {
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	const [loadingUnlockUser, setLoadingUnlockUser] = useState(false);
	const [errorUnlockUser, setErrorUnlockUser] = useState('');
	// const [errorReenvioChaveDesbloqueio, setErrorReenvioChaveDesbloqueio] = useState('');
	const [key, setKey] = useState(query.get('key'));

	// const handleReenvioLinkDesbloqueio = () => {};

	const unlockUser = () => {
		setLoadingUnlockUser(true);
		const unlock = {
			key,
			returnUrl: query.get('ReturnUrl'),
			redirectUri: query.get('RedirectUri'),
		};

		HeimdallApi.UnlockUser(unlock)
			.then((response) => {
				setLoadingUnlockUser(false);
				if (response.hasError) {
					setErrorUnlockUser(response.message);
				} else {
					// window.location = `${window.location.origin}/NotFound`;
					if (response.redirectUri) {
						window.location.href = response.redirectUri;
						return;
					}

					const notfound = `${window.location.origin}/NotFound`;
					window.location.href = notfound;
				}
			})
			.catch(() => {
				setErrorUnlockUser('Falha ao desbloquear o usuário.');
				setLoadingUnlockUser(false);
			});
	};

	const handleKeyPressDesbloquearUsuario = (event) => {
		if (event.key === 'Enter') {
			unlockUser();
		}
	};

	const handleClickDesbloquearUsuario = () => {
		unlockUser();
	};

	return (
		<Paper className="box box__form mb-50" style={{ overflow: 'hidden' }}>
			<h1>Desbloqueio de Usuário</h1>

			{errorUnlockUser || null}

			{key ? (
				<p className="mb-15">Clique no botão abaixo para desbloquear seu usuário.</p>
			) : (
				<p className="mb-15">Coloque a chave de desbloqueio enviada por e-mail no campo abaixo e clique em desbloquear usuário.</p>
			)}
			<div className="box-unlock-user">
				<TextField
					fullWidth
					id="outlined-required"
					label="Chave de desbloqueio"
					className="mb-15"
					variant="outlined"
					value={key}
					onChange={(e) => setKey(e.target.value)}
					onKeyPress={handleKeyPressDesbloquearUsuario}
				/>

				<FbitsButton
					fullWidth
					color="secondary"
					size="large"
					disabled={loadingUnlockUser}
					text={!loadingUnlockUser ? 'DESBLOQUEAR USUÁRIO' : 'Carregado...'}
					onClick={handleClickDesbloquearUsuario}
				/>

				<p className="mt-30 pt-30">
					Em caso de problemas, entre em contato com nossa equipe através da
					<a href={HeimdallSettings.helpCenter.canaisAtendimento} target="_blank" rel="noopener noreferrer" className="text-link ml-5 link">
						Central de Atendimento.
					</a>
				</p>
			</div>
		</Paper>
	);
}
