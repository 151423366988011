import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: '#59667B',
		color: 'white',

		'&:hover': {
			backgroundColor: '#343B47',
		},
	},
}));

export function FbitsButton({ variant, size, color, text, disabled, href, target, fullWidth, className, onClick }) {
	const classes = useStyles();
	return (
		<>
			<Button
				fullWidth={fullWidth}
				variant={variant}
				size={size}
				color={color}
				disabled={disabled}
				href={href}
				target={target}
				style={size === 'large' ? { height: '50px' } : null}
				className={color === 'default' ? `${`${classes.root} ${className}`}` : className}
				onClick={onClick}
			>
				{text}
			</Button>
		</>
	);
}

FbitsButton.propTypes = {
	variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
	size: PropTypes.oneOf(['small', 'medium', 'large']),
	color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
	text: PropTypes.string,
	href: PropTypes.string,
	target: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
};

FbitsButton.defaultProps = {
	variant: 'contained',
	size: 'medium',
	color: 'primary',
	text: '',
	href: '',
	target: '',
	className: '',
	fullWidth: false,
	disabled: false,
};
