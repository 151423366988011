import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { Crypto, Storage } from '../../utils';
import { FbitsButton } from '../FbitsButton';
import { InputPassword, InputCheckbox } from '../FbitsInputs';
import { ApiSettings } from '../../apis/ApiSettings';

import { FbitsLoginContext } from './index';
import { HeimdallApi, LgpdApi } from '../../apis';
import Lgpd from '../../layout/Lgpd';

export function Login({ nextStep }) {
	const context = React.useContext(FbitsLoginContext);
	const location = useLocation();
	const { lgpd, setLgpd, setType2FA, setUser, setEmailValidacao, setIsMultitenant, setReturnUrl } = context;

	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [response, setResponse] = React.useState({});
	const [modalLgpd, setModalLgpd] = React.useState(false);
	const [values, setValues] = React.useState({
		login: '',
		senha: '',
		remember: false,
	});

	React.useEffect(() => {
		const getLocalStorage = Storage.get('access');
		if (getLocalStorage) {
			setValues(Crypto.decrypt(getLocalStorage));
		}
		// Se é uma url de login e não possui return url na query string redirecionada para home.
		if (window.location.pathname.includes('Account/Login') && !window.location.search.includes('ReturnUrl')) {
			const url = ApiSettings.backendUrl.replace('/api/', '');
			window.location.href = url;
		}
	}, []);

	const aceiteLgpd = () => {
		setModalLgpd(false);

		if (context.lgpd.termo !== null) {
			const data = {
				returnUrl: response.returnUrl,
				loja: response.userStore,
				usuario: response.userName,
				ip: '',
				versoes: context.lgpd.termo.versoes,
			};

			LgpdApi.LgpdAceite(data)
				.then((res) => {
					if (res.value.termoAceito) {
						const url = ApiSettings.backendUrl.replace('/api/', '');
						window.location.href = `${url}${res.value.returnUrl}`;
					}
				})
				.catch(() => {
					console.error('Erro ao aceitar termos da Lei Geral de Proteção de Dados');
				});
		}
	};

	const continuarSemAceitar = () => {
		setModalLgpd(false);
		LgpdApi.ContinuarSemAceitar({ returnUrl: response.returnUrl, loja: response.userStore })
			.then((res) => {
				const url = ApiSettings.backendUrl.replace('/api/', '');
				window.location.href = `${url}${res.value.returnUrl}`;
			})
			.catch(() => {
				console.error('Erro ao concluir login sem aceitar termos lgpd');
			});
	};

	const handleRememberPassword = (event) => {
		setValues({ ...values, remember: event.target.checked });

		if (event.target.checked) {
			values.remember = event.target.checked;

			Storage.set('access', Crypto.encrypt(values));
		} else {
			Storage.delete('access');
		}
	};

	const handleLoginForm = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleEntrar = () => {
		setLoading(true);

		const query = new URLSearchParams(location.search);

		const auth = {
			username: values.login,
			password: values.senha,
			returnUrl: query.get('ReturnUrl'),
			searchQueryString: window.location.search,
		};
		HeimdallApi.Login(auth)
			.then((res) => {
				setLgpd({
					user: res.value.login,
					termo: res.value.termo,
					aceiteStatus: res.value.aceiteStatus,
					isAdmin: res.value.isAdminStore,
				});

				setIsMultitenant(res.value.storeNotFound);
				setUser({
					userName: res.value.userName,
					userId: res.value.id,
					userLogin: values.login,
					userPass: values.senha,
					nome: res.value.nome || '',
				});
				const url = ApiSettings.backendUrl.replace('/api/', '');
				const returnUrl = `${url}${res.value.returnUrl}`;
				setReturnUrl(returnUrl);

				if (!res.value.isAuthenticated && !res.value.storeNotFound) {
					setError(res.value.authenticationMessage);
				} else if (res.value.partialAuthentication) {
					setEmailValidacao(res.value.email);
					setType2FA(res.value.type2FA);
					nextStep('authentication');
				} else if (!res.value.partialAuthentication && !res.value.isValid && res.value.storeNotFound) {
					nextStep('multitenant');
				} else if (!res.value.profileCompleted) {
					nextStep('profileComplete');
				} else if (!res.value.aceiteStatus && !res.value.storeNotFound) {
					setResponse({
						returnUrl: res.value.returnUrl,
						userName: res.value.userName,
						userStore: res.value.store,
					});
					setModalLgpd(true);
				} else {
					window.location.href = returnUrl;
				}

				setLoading(false);
			})
			.catch((e) => {
				if (e && e.error && e.error.value && e.error.value.authenticationMessage) {
					setError(e.error.value.authenticationMessage);
				} else {
					setError('Login inválido, confira seus dados e tente novamente');
				}
				setLoading(false);
			});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			handleEntrar();
		}
	};

	return (
		<div className="slide-left">
			<h1>Acesse seu admin</h1>

			<form noValidate autoComplete="off">
				<TextField
					fullWidth
					required
					autoFocus
					variant="outlined"
					className="mb-15"
					placeholder="Digite seu login"
					label="Login"
					name="login"
					id="login"
					value={values.login}
					onChange={handleLoginForm('login')}
					onKeyPress={handleKeyPress}
				/>

				<InputPassword
					placeholder="Digite sua senha"
					name="senha"
					value={values.senha}
					label="Senha"
					labelWidth={50}
					className="mb-15"
					onChange={handleLoginForm('senha')}
					onKeyPress={handleKeyPress}
				/>

				<Typography color="error" style={{ paddingBottom: 10, paddingLeft: 5 }}>
					{error}
				</Typography>

				<FbitsButton
					fullWidth
					color="secondary"
					text={!loading ? 'Entrar' : 'Carregando...'}
					disabled={loading}
					size="large"
					onClick={handleEntrar}
				/>

				<InputCheckbox checked={values.remember} onChange={handleRememberPassword} id="lembrarLogin" label="Lembrar meu login" />
			</form>

			<div className="form__link">
				<button
					onClick={() => nextStep('recoverPasswordSend')}
					type="button"
					className="text-default text-link text-decoration--underline link__position link__position--bottom-left"
				>
					RECUPERAR SENHA
				</button>

				<button
					onClick={() => nextStep('firstAccessSend')}
					type="button"
					className="text-default text-link text-decoration--underline link__position link__position--bottom-right"
				>
					PRIMEIRO ACESSO
				</button>
			</div>

			{modalLgpd ? (
				<Lgpd
					open={modalLgpd}
					accept={aceiteLgpd}
					data={lgpd.termo}
					continuar={continuarSemAceitar}
					exibirTermo={lgpd?.isAdmin || false}
					close={() => setModalLgpd(false)}
				/>
			) : null}
		</div>
	);
}

Login.propTypes = {
	nextStep: PropTypes.func.isRequired,
};
