import React from 'react';
import { Grid } from '@material-ui/core';
import { HeimdallSettings } from '../HeimdallSettings';

export default function Footer() {
	return (
		<footer className="footer">
			<Grid container direction="row" justifyContent="center" alignItems="flex-end">
				<Grid item xs={12} md={6}>
					<p className="mobile__d--none" style={{ margin: 'auto 6.43rem' }}>						
						&copy; {new Date().getFullYear()} Wake Commerce
					</p>
				</Grid>

				<Grid item xs={12} lg={6} className="text-center">
					<p>
						Entre em contato com nosso atendimento através de Chat,{' '}
						<a
							className="text-white text-link"
							href={HeimdallSettings.helpCenter.canaisAtendimento}
							target="_blank"
							rel="noopener noreferrer"
						>
							chamado
						</a>{' '}
						ou telefone:{' '}
						<b>
							<a className="text-white text-link" href={HeimdallSettings.helpCenter.telAtendimentoUrl}>
								{HeimdallSettings.helpCenter.telAtendimentoNumber}
							</a>
						</b>
					</p>
				</Grid>

				<Grid item xs={12}>
					<p className="text-center mobile__d--block">
						<img
							src={`${process.env.PUBLIC_URL}/images/locaweb.png`}
							alt="Localweb"
							className="mr-15"
							width="71"
							height="16"
							style={{ top: 3, position: 'relative' }}
							loading="lazy"
						/>
						&copy; {new Date().getFullYear()} Wake Commerce
					</p>
				</Grid>
			</Grid>
		</footer>
	);
}
