import React, { useState } from 'react';
import { Paper, TextField } from '@material-ui/core';
import { ArrowForwardOutlined, Error as ErrorIcon } from '@material-ui/icons';

import { HeimdallSettings } from '../HeimdallSettings';

export function AccessDenied() {
	const [store, setStore] = useState('');
	const handleKeyPressGoToStore = (event) => {
		if (event.key === 'Enter') {
			window.location.replace(`https://${store}.fbits.app`);
		}
	};

	const handleGoToStore = () => {
		window.location.replace(`https://${store}.fbits.app`);
	};

	return (
		<Paper className="box box__form mb-50" style={{ overflow: 'hidden' }}>
			<h1 className="mb-30 pb-30">
				<ErrorIcon style={{ color: '#D32F2F', position: 'relative', top: 4 }} /> Acesso Negado
			</h1>
			<p className="mb-15">Digite o nome da sua loja no modelo de URL abaixo e clique na seta para acessar:</p>
			<div className="box-loja__url">
				<TextField
					id="outlined-required"
					label="Nome da loja"
					variant="outlined"
					onChange={(e) => setStore(e.target.value)}
					onKeyPress={handleKeyPressGoToStore}
				/>
				<span className="d-inline-block ml-10">
					.fbits.app
					<button type="button" className="box-loja__button" onClick={handleGoToStore}>
						<ArrowForwardOutlined />
					</button>
				</span>

				<p className="mt-30 pt-30">
					Em caso de problemas, entre em contato com nossa equipe através da
					<a href={HeimdallSettings.helpCenter.canaisAtendimento} target="_blank" rel="noopener noreferrer" className="text-link ml-5 ">
						Central de Atendimento.
					</a>
				</p>
			</div>
		</Paper>
	);
}
